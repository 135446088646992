import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./comboWrapperEvents.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"


import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import 'react-tooltip/dist/react-tooltip.css'
import LatestEvents from "../LatestEvents"


export default function ComboWrapperEvents(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      things_to_do_ar:translateString(language: AR, string: "الفعاليات واألنشطة")
      things_to_do_en:translateString(language: EN, string: "THINGS TO DO")
    }
  }`)

  const tags = props?.data?.allWpEventcategory?.edges
  let categoryListEvent = []




  tags?.map((item, index) => {
  if (item.node.language.locale.indexOf(locale) !== -1) {
    return categoryListEvent.push(item.node)
  }
})


  let hash="";
  if(categoryListEvent.length>0){
    hash="#"+categoryListEvent[0].id;
  }

  if (typeof window !== 'undefined') {
    try{
  if(sessionStorage.getItem('eventhash')){
  //  hash=sessionStorage.getItem('eventhash');
  }
}catch(ex){
        
}
}
  try{
  if (window.location.hash) {
   // hash=window.location.hash;
  }
  }catch(err){
    
  }

  useEffect(
    () => {
      if (hash) {
         setActiveTab(hash)
      }
    }
  )

  function setActiveTab(hashurl){
    var elements = document.getElementsByClassName("listhead")[0].getElementsByTagName("li");
      for (var i = 0; i < elements.length; i++) {
      if(elements[i].dataset.tab==hashurl){
        elements[i].classList.add("active");
      }else{
        elements[i].classList.remove("active");
      }
    }


    var elementsdown = document.getElementsByClassName("listbottom")[0].getElementsByTagName("div");
  
      for (var i = 0; i < elementsdown.length; i++) {
        if(elementsdown[i].id!=""){
      if('#'+elementsdown[i].id==hashurl){
        elementsdown[i].classList.add("active");
      }else{
        elementsdown[i].classList.remove("active");
      }
    }
    }
  }
  function updatehash(hash){
    if (typeof window !== 'undefined') {
      try{
      sessionStorage.setItem('eventhash', hash);
      }catch(ex){
        
      }
    }
    
  var arabic = document.querySelectorAll(".smail-menu-item,.arabic");
    try{
     for ( let i=0 ; i < arabic.length ; i++) {
      var elem = arabic[i];
       if(elem.childNodes){
       const url = elem.childNodes[0].href;
       const urlObj = new URL(url);
        urlObj.hash = hash;
        const result = urlObj.toString();
       elem.childNodes[0].href=result;
       }

      }
    }catch(aaa){

    }
  }
  return (
    <>
      <section className="combo-wrapper-events" id="events-data">
        <div className="container">
        <div className="col-group">
        <div className="col-dt-8">
        <h2 className="subheadlocationn">    {string_translation(stringTranslations, "things_to_do", locale)}</h2>
        <hr className="hrlinesmalll" />
        </div>
        <div className="col-dt-4"></div>
        </div>
          <div className="combo-box-tab"> 
            <ul className="tabs tabs-list h2 listhead" data-controls="#eventTab">
            {categoryListEvent.map((item,index) => {
               return (
                <li data-tab={'#'+item.id} onClick={(e) => updatehash('#'+item.id)}   className={hash == '#'+item.id? 'active': '' }>
               <img className="iconImageEvent" src={item.promenadeEventCategory?.icon?.localFile?.publicURL} /> &nbsp;  {item.name}  
                </li>
                )
          
                  })}
            </ul>
          </div>
          <div className="tab-container listbottom" id="eventTab">
          {categoryListEvent.map((item,index) => {
               return (
            <div  id={item.id} className={hash == '#'+item.id? 'active resizing': 'resizing' }>
       
           <LatestEvents props={props?.data.allWpPromenadeevent} id={item.id} />
            </div>
              )
          
            })}
          
          
          </div>
        
        </div>
      </section>
    </>
  )
}

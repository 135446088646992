import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./PromenadeLocation.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import SliderNavigation from '../SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import GoogleMapReact from 'google-map-react'

import { createMarkup } from "../../../utils"

export default function PromenadeLocation(props) {
  const [mapBounds, setMapBounds] = useState(null);

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const handleApiLoaded = (map, maps) => {
   
    const bounds = new maps.LatLngBounds();

    props?.data?.locations?.forEach((marker) => {
      bounds.extend(new maps.LatLng(marker.coordinates.split(',')[0],marker.coordinates.split(',')[1]));
    });

    setMapBounds(bounds);
    map.fitBounds(bounds);
map.setZoom(11);
  };
 
 
  console.log(props);
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      getting_here_ar:translateString(language: AR, string: "الوصول إلى هنا")
      getting_here_en:translateString(language: EN, string: "GETTING HERE")
       faq_ar:translateString(language: AR, string: "التعليمات")
       faq_en:translateString(language: EN, string: "FAQ")
       park_rules_ar:translateString(language: AR, string: "قواعد الحديقة")
       park_rules_en:translateString(language: EN, string: "PARK RULES")
       visitor_services_ar:translateString(language: AR, string: "خدمات الزوار")
       visitor_services_en:translateString(language: EN, string: "VISITOR SERVICES")
 }
  }`)


  const Marker = ({ text, imgSrc }) => (
    <div style={{ position: 'relative', transform: 'translate(-50%, -50%)' }}>
      <img 
        src={imgSrc} 
        alt={text} 
        style={{ width: '100px', height: 'auto' }} 
      />
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#000' }}>
        {text}
      </div>
    </div>
  );


  const mapOptions = {
    scrollwheel: true, 
    draggable: true,    
    fullscreenControl: true,
    zoomControl: false,
    disableDefaultUI: false, 
  };
  return (
    <>
      <section className="combo-wrapper bus-combo">
        <div className="container">
          <hr className="hrline"></hr>
    
        
            <div className="active resizing" id="the-map">
              <div className="img-wrapper map bustimingblock">

              
                  <div className="col-dt-8">
                  <h2 className="subheadlocationn">    {string_translation(stringTranslations, "getting_here", locale)}</h2>
                    <hr className="hrlinesmalll" />
                    <div className="col-dt-12 gmap">
                    <GoogleMapReact
  bootstrapURLKeys={{ key: 'AIzaSyBImgE5xrlL6vlN-XMjRErx2Dk9LNEKPvg' }}
  defaultCenter={{
    lat: 24.7136,
    lng: 46.6753
  }}
  defaultZoom={9}
  yesIWantToUseGoogleMapApiInternals
  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
  options={mapOptions}
>

{props?.data?.locations?.map((marker, index) => (
          <Marker
            key={index}
            lat={marker.coordinates.split(',')[0]}
            lng={marker.coordinates.split(',')[1]}
            text=""
            imgSrc={marker.marker.localFile.publicURL}
          />
        ))}
</GoogleMapReact></div> 

                  </div>
                  <div className="col-dt-4 sidelocation">
                <h2 className="subheadlocation">  &nbsp;</h2>
             
             <h1><img src="/images/contact-us/location-icon.png" />{props?.data?.title}</h1>
             <div className="parag" dangerouslySetInnerHTML={createMarkup(props?.data?.text)}></div>
<ul>
  <li>{string_translation(stringTranslations, "faq", locale)}</li>
  <li>{string_translation(stringTranslations, "park_rules", locale)}</li>
  <li>{string_translation(stringTranslations, "visitor_services", locale)}</li>
</ul>
                  </div>
               
         
        
                             
              </div>
            </div>
         
       
        
        </div>
      </section>
    </>
  )
}

import React, {useContext,useState } from "react"
import "./EventSlider.scss"
import { createMarkup, string_translation } from "../../../utils"
import {useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

export default function EventSlider(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const [formdState, setformdState] = useState(false)
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        whats_on_ar:translateString(language: AR, string: "ماذا يجري")
        whats_on_en:translateString(language: EN, string: "WHAT'S ON")
        register_now_ar:translateString(language: AR, string: "سجل الآن")
        register_now_en:translateString(language: EN, string: "Register Now")
      }
    }
  `)



  return (
    <>
      <section className="event-slider">
      
          <div className="container">
          <div className="heading-wrapper">
          <div className="heading h2">{string_translation(stringTranslations,"whats_on", locale)}</div>
          </div>
          <Swiper
          
            loop={true}
        slidesPerView={1}
        spaceBetween={10}
        navigation={true} 
        pagination={true} modules={[Pagination,Navigation]}
   
        dir={locale === "en" ? "ltr" : "rtl"}

        className="mySwiper"
      >

{props?.data?.map((slide, index) => (
                  <SwiperSlide key={index+1}>
                    <div className="col-group mainDiv">
                    <div className="img-wrapper col-dt-6">
                      <img src={slide?.image?.localFile?.publicURL} alt={slide?.image?.altText} />
                    </div>
                    <div className="text-wrapper col-dt-6">
<h1>{slide?.title}</h1>
<div className="parag" dangerouslySetInnerHTML={createMarkup(slide?.text)}></div>
{slide?.link &&
                  <a className="click-link buslinkspace" href={slide?.link} target="_blank" rel="noopener noreferrer">{string_translation(stringTranslations, "register_now", locale)}  </a>
                  }
                    </div>
                    </div>
                  </SwiperSlide> 
                ))}
      </Swiper>
          </div>
 
      </section>
    </>
  )
}

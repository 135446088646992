import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import GetintouchVendor from '../components/GetinTouchVendor'
import Layout from "../components/layout"
import WhoWeAreVendor from "../components/WhoWeAreVendor"
import VendorFirstComponent from "../components/VendorFirstComponent"
import LifeSliderVendor from "../components/LifeSliderVendor"
import CallToActionBannerVendor from "../components/CallToActionBannerVendor"
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import WhoWeAreButton from "../components/WhoWeAreButton"
import WhoWeAreWorkZone from "../components/WhoWeAreWorkZone"
import LifeSliderVendorNew from "../components/LifeSliderVendorNew"
import ComboWrapperProcurement from "../components/ComboWrapperProcurement"
import GeneralEnquiryPromenade from "../components/GeneralEnquiryPromenade"
import PartnerSlider from "../components/PartnerSlider"
import PromenadeLocation from "../components/PromenadeLocation"
import EventSlider from "../components/EventSlider"
import WhoWeArePromenade from "../components/WhoWeArePromenade"
import ComboWrapperEvents from "../components/ComboWrapperEvents"
export default function Promenade(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
     
        <WhoWeArePromenade
          image={data.wpPage?.promenadeData?.headerImage}
          text={data.wpPage?.title}
        /> 
        <EventSlider data={data?.wpPage?.promenadeData?.sliderData}/>
        <ComboWrapperEvents data={data} />
        <PromenadeLocation  data={data?.wpPage?.promenadeData?.direction}/>
        <PartnerSlider data={data?.wpPage?.promenadeData?.partners}/>
        <GeneralEnquiryPromenade data={data?.wpPage?.promenadeData?.contactForm}/>

      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query PromenadeData($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageDescription {
        pageDescription
      }
            promenadeData {
      contactForm
      direction {
        text
        title
        locations {
          coordinates
          marker {
            localFile {
              publicURL
            }
          }
        }
      }
      headerImage {
        localFile {
          publicURL
        }
      }
      partners {
        logo {
          localFile {
            publicURL
          }
            altText
        }
      }
      sliderData {
        link
        text
        title
        image {
          localFile {
            publicURL
          }
        }
      }
    }
    
     }

  allWpEventcategory {
    edges {
      node {
        promenadeEventCategory {
          icon {
            localFile {
              publicURL
            }
          }
        }
        name
        id
        databaseId
        language {
          id
          locale
        }
      }
    }
  }
  allWpEventactivities {
    edges {
      node {
        name
        id
        databaseId
        language {
          id
          locale
        }
      }
    }
  }
    allWpPromenadeevent(sort: {fields: date, order: DESC}) {
    edges {
      node {
        title
        language {
          id
          locale
        }
        excerpt
        date(formatString: "DD-MM-YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        content
        slug
        uri
        translations {
          uri
        }
        eventactivities {
          nodes {
            name
            id
            databaseId
            language {
              id
              locale
            }
          }
        }
        eventcategories {
          nodes {
            id
            databaseId
            name
            language {
              id
              locale
            }
          }
        }
        promenadeEvent {
          time
          registrationLink
          location
          duration
          date
          partnerLogo {
            localFile {
              publicURL
            }
          }
          gallery {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
  }
`

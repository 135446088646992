import * as React from "react"
import {  useStaticQuery, graphql } from "gatsby"
import {  string_translation } from "../../../utils"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import "./WhoWeArePromenade.scss"


export default function WhoWeArePromenade({ text, image }) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        explore_ar:translateString(language: AR, string: "يستكشف")
        explore_en:translateString(language: EN, string: "EXPLORE")
      }
    }
  `)
  return (
    <>
      <section className="who-we-are-promenade">
        <div className="headerMainImage" style={{backgroundImage:`url(${image?.localFile?.publicURL})`}}>
       <img className="headerSecondImage" src="/images/project-in-number/Projectinnumbers/bike-svg.svg" />
       <a className="click-link" href="#events-data" >{string_translation(stringTranslations, "explore", locale)}  </a>

       </div>
       <h1>{text}</h1>
      </section>
    </>
  )
}
